import React from 'react'
import styled from 'styled-components';
import { IGatsbyImageData } from "gatsby-plugin-image";

import { HomeHeader } from "../components/Headers/HomeHeader";
import { SEO, Layout, Footer, Hero } from "../components";
import RichText from '../components/RichText';

type BlogPostProps = {
  pageContext: {
    post: {
      slug: string,
      title: string,
      body: {
        raw: string
      },
      seoDescription?: string,
      thumbnail: {
        description: string,
        gatsbyImageData: IGatsbyImageData,
        file: {
          url: string
        }
      }
    }
  };
}

const Article = styled.article`
  display: block;
  padding: 4rem 0;
  width: 100%;
  background-color: #ffffff;

  ul {
    margin: 0.5rem 0;
  }

  li {
    display: inline;
  }

  section {
    max-width: 41.5rem;
    margin: auto;
  }

  @media (min-width: 1201px) {
    section {
      margin-top: 2.387rem;
      margin-bottom: 18.125rem;
      max-width: 46.625rem;
    }
  }
`;

const BlogPost = ({ pageContext }: BlogPostProps) => {
  const { title, body, seoDescription, thumbnail } = pageContext.post;
  console.log(body);

  return (
    <Layout
      header={<HomeHeader style={{ marginBottom: 0 }} />}
      footer={<Footer />}
    >
      <SEO
        title={title}
        desc={seoDescription}
        image={thumbnail.file.url}
      />
      <Article>
        <div className="container">
          <Hero featuredImage={thumbnail} reverse>
            <header>
              <h1>{title}</h1>
            </header>
          </Hero>
          <RichText content={body} />
        </div>
      </Article>
    </Layout >
  )
}

export default BlogPost
